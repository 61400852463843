import { defineAsyncComponent } from 'vue';

// structure components
const ComponentsBase = [
  'Item',
  'ItemGroup',
  'ItemSubGroup',
];

// structure components
const ComponentsDashboard = [
  'AppBar',
  'Drawer',
  'DrawerTop',
  'Settings',
  'Footer',
  'View',
  'App',
];

// content components
const ComponentsUI = [
  'LanguageSelect',

  'UserLogin',
  'SignupRegistration',
  'RegisterMember',
  'UserProfile',

  'Metrics',
  'Autoship',
  'MetricsHistory',
  'MetricWidget',
  'RefLink',

  'WalletBalance',
  'WalletHistory',
  'ScheduleHistory',
  'AccountToAccount',
  'PayoutRequest',

  'FrontlineTable',
  'DownlineTable',
  'GenealogyTable',
  'VisualTree',
  'VisualBinaryTree',
  'UserPop',
  'Aux',
  'PayoutRequestHistory',
  'TransactionLog',
  'RefLinkParts',
  'LeaderBoard',
  'RankProgress',
  'ProgressMetric',
  'Feedback',
  'WalletToWallet',
  'LinkBox',
  'CarouselWidget',
  'BadgeBox',
  'MetricChartLine',
  'PropertiesBox',
  'AvatarBox',
  'WidgetCard',
  'LanguageSelectBtn',

  'ApiSelect',
  'GravManagerWrapper',

];

function loadComponentFactoryFn(componentName) {
  return async () => {
      let component = undefined;
      try {
        // console.log('Trying to load fascade', componentName);
        component = await import(`@/components/ui/${componentName}Facade.vue`);
      } catch (e) {
        // console.log('Trying to load w/o fascade', componentName);
        component = await import(`@/components/ui/${componentName}.vue`);
      }
      return component;
  };

}

export default {

  install(app) {

    ComponentsBase.forEach(componentName =>
      app.component(`Base${componentName}`, defineAsyncComponent(() => import(`@/components/base/${componentName}.vue`)),
      ),
    );

    ComponentsDashboard.forEach(componentName =>
      app.component(`Dashboard${componentName}`, defineAsyncComponent(() => import(`@/components/dashboard/${componentName}.vue`)),
      ),
    );

    ComponentsUI.forEach(componentName =>
      app.component(`Ui${componentName}`, defineAsyncComponent(loadComponentFactoryFn(componentName)),
      ),
    );
  },

};
