
/* This script (in production) initializes VUE and passes all outside parameters
** (from GRAV) to the dashboard-app component
**
** In development mode this is done in /main.ts + /App.vue
*/

import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import i18n, { initLanguage } from '../i18n';

import vuetify from '../plugins/vuetify';
import components from '../plugins/components';
import inputmask from '../plugins/directives/inputmask';

import 'vuetify/dist/vuetify.min.css';

import * as Sentry from "@sentry/browser";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const pkg = require('../../package.json');

console.log('ui2:', pkg.version);

Sentry.init({
  dsn: "https://53da3e38a7f8b7600d0e4361def17ca0@sentry.ds.mlmsoft.cloud/2",

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  release: `ui2-components@${pkg.version}`,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/.+\.(cloud|app|mlmsoft\.com)\/api3/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


function initAppInsideGrav(createApp, settings) {

  console.log('UI2: initAppInsideGrav', settings);

  const {
    itemsMenu,
    currentLocale,
    themeJson,
    languages,
    loginPath,
    registrationPath,
    projectName,
    idMask,
    preferredCountries,
    onlyCountries,
    currentTranslationsJson,
    logoBig,
    logoSmall,
    useExternalId,
    logoutRedirectUrl,
    accountSearchFields,
    fullNameField,
    doNotRedirectToLogin,
  } = settings;

  let themeSettings = {};
  if (themeJson) {
    try {
      themeSettings = JSON.parse(themeJson);
    } catch (e) {
      console.log('i18n: Error parsing THEME_V_JSON', themeJson);
    }
  }

  let currentTranslationsOverride = {};
  if (currentTranslationsJson && !currentTranslationsJson.startsWith('/')) {
    try {
      currentTranslationsOverride = JSON.parse(currentTranslationsJson);
    } catch (e) {
      console.log('i18n: Error parsint translations override', currentTranslationsJson);
    }
  }

  const App = {
    name: 'App',
    data() {
      return {
        itemsMenu,
        projectName,
        themeSettings,
        languages,
        loginPath,
        registrationPath,
        idMask,
        preferredCountries,
        onlyCountries,
        logoBig,
        logoSmall,
        useExternalId,
        logoutRedirectUrl,
        accountSearchFields,
        fullNameField,
        doNotRedirectToLogin,
      };
    },

    computed: {
      pageTitle() {
        return `${projectName} ${this.activeRouteName}`;
      },

      activeRouteName() {
        let itemMenu = this.itemsMenu.find(
          a => a.active || (a.children && a.children.find(b => b.active)),
        );

        if (!itemMenu) {
          itemMenu = this.itemsMenu.find(a => a.href === '/');
          if (!itemMenu) {
            return '';
          }
        }

        return (
          itemMenu.title +
          (itemMenu.active ? '' : ` > ${itemMenu.children.find(b => b.active).title}`)
        );
      },
    },

  };

  const app = createApp(App);
  const pinia = createPinia();
  pinia.use(piniaPluginPersistedstate);

  app
    .use(pinia)
    .use(vuetify)
    .use(i18n)
    .use(components)
    .use(inputmask);

  app.mount('#app'); // in base.twig or simple.twig (grav)

  i18n.global.locale.value = currentLocale;
  initLanguage(currentLocale, currentTranslationsOverride);

}

export { initAppInsideGrav };
