import Inputmask from 'inputmask';
import isEqual from 'lodash.isequal';

Inputmask.extendDefaults({
  autoUnmask: true,
  showMaskOnHover: false,
});

Inputmask.extendDefinitions({
  // переопределяем стандартный definition чтобы пропускал только латиницу
  a: {
    validator: '[A-Za-z]',
  },
});

/**
 * Для добавления нужной маски нужно добавить соответствующий alias в структуру ниже,
 * либо воспользоваться предустановленным alias
 * @see {@link https://github.com/RobinHerbots/Inputmask#aliases|Aliases}
 */
Inputmask.extendAliases({
  'currency': {
    max: 1_000_000_000,
    groupSeparator: ' ',
    autoUnmask: true,
    allowMinus: false,
    rightAlign: false,
    digitsOptional: true,
    SetMaxOnOverflow: true,
    positionCaretOnClick: 'lvp',
  },
});


/**
 * Функция для проверки существования необходимого alias
*
* @param {String} aliasName - имя alias маски
* @returns {Boolean}
*/
function isAliasExist(aliasName) {
  return !!Inputmask.prototype.aliases[aliasName];
}

/**
 * Функция для инициализации экзкмпляра Inputmask и добевления его к элементу
 * @see {@link https://vuejs.org/guide/reusability/custom-directives.html#directive-hooks|Хуки}
*
* @param {HTMLElement} element - объект елемента
* @param {Object} bindings - аргумент хука vue-директивы
* @param {String} bindings.arg - аргумент передаваемый при добавлении директивы v-mask:arg
*/
function setupInputmask(element, bindings = {}) {
  const el = element.querySelector('input') || element;
  const { arg: alias, value } = bindings;

  if (el && value) {
    new Inputmask(value).mask(el);
    
    addEventHandlers(el);
  } else if (el && isAliasExist(alias)) {
    new Inputmask({ alias }).mask(el);
    
    addEventHandlers(el);
  }
}

/**
 * Функция для добавления обработчиков событий на элемент
*
* @param {HTMLElement} element - элемент страницы к которому добавляется директива
*/
function addEventHandlers(element) {
  const el = element.querySelector('input') || element;
  const repairSync = () => {
    if (el.value) {
      const syntEvent = new Event('input', {
        bubbles: true,
        cancelable: true,
      });
      
      el.dispatchEvent(syntEvent);
    }
  };
  
  const transitionHandler = (event) => {
    if (event.propertyName === 'background-color') {
      setTimeout(repairSync, 0);
    }
  };
  
  const animationHandler = (event) => {
    if (event.animationName === 'onautofillstart') {
      setTimeout(repairSync, 0);
    }
  };

  const pasteHandler = () => {
    setTimeout(repairSync, 0);
  };
  
  el.addEventListener('transitionstart', transitionHandler, true);
  el.addEventListener('animationstart', animationHandler, true);
  el.addEventListener('paste', pasteHandler, true);
  
  el.unsubscribe = () => {
    el.removeEventListener('transitionstart', transitionHandler, true);
    el.removeEventListener('animationstart', animationHandler, true);
    el.removeEventListener('paste', pasteHandler, true);
  };
}

/**
 * Для применения маски на инпуте нужно добавить директиву и указать аргументом alias нужной маски,
 * либо передачей объекта с конфигурацией inputmask
 * @see {@link https://github.com/RobinHerbots/Inputmask|Inputmask}
 *
 * v-mask:phone
 * v-mask:date
 *
 * v-mask="{ alias: 'phone' }"
 * v-mask="{ alias 'date' }"
 *
 * так же работает динамическое изменение маски (только при использовании объекта в качесте значения директивы)
 * @see {@link https://vuejs.org/guide/reusability/custom-directives.html#directive-hooks|Аргументы функций-обработчиков состояний директивы}
 * const someValue = { alias: 'phone' };
 * ...
 * v-mask="someValue"
 */
export default {

  install(app) {

    app.directive('mask', {
      beforeMount: setupInputmask,

      updated(element, bindings) {
        const el = element.querySelector('input') || element;
        const { value: params, oldValue: oldParams } = bindings;

        const isParamsUpdated = !isEqual(params, oldParams);

        if (isParamsUpdated) {
          el.value = '';

          const syntEvent = new Event('input', {
            bubbles: true,
            cancelable: true,
          });

          el.dispatchEvent(syntEvent);

          if (el.inputmask) {
            el.inputmask.option(params);
          } else {
            setupInputmask(el, bindings);
          }
        }
      },

      unmounted(element) {
        const el = element.querySelector('input') || element;

        if (typeof el.unsubscribe === 'function') {
          el.unsubscribe();
        }

        if (el.inputmask && typeof el.inputmask.remove === 'function') {
          el.inputmask.remove();
        }
      },
    });
  },
};
