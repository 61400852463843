import { createI18n } from 'vue-i18n';

import enVuetify from 'vuetify/lib/locale/en.mjs';
import enCore from '@/locales/core/en';

async function loadVuetifyMessages(locale) {
  try {
    const vuetifyMessages = await import(`vuetify/lib/locale/${locale}.mjs`);
    return vuetifyMessages.default;
  } catch (error) {
    console.error(`Error loading Vuetify messages for ${locale}:`, error);
    return {};
  }
}

async function loadCoreMessages(locale) {
  try {
    const coreMessages = await import(`@/locales/core/${locale}.js`);
    return coreMessages.default;
  } catch (error) {
    console.log(`i18n: no core messages for ${locale}, relying on override.`);
    return {};
  }
}


export async function initLanguage(locale, translationOverride = undefined) {

  if (!i18n.global.availableLocales.includes(locale)) {

    const vuetifyMessages = await loadVuetifyMessages(locale);
    const coreMessages = await loadCoreMessages(locale);

    i18n.global.setLocaleMessage(locale, {
      $vuetify: vuetifyMessages,
      ...coreMessages,
    });

    i18n.global.mergeDateTimeFormat(locale, {
      long: longDatetimeFormat,
      short: shortDate,
      medium: mediumDate,
    });

    i18n.global.availableLocales.push(locale);

    if (i18n.global.locale === '') {
      i18n.global.locale = locale;
    }
    console.log('i18n: added new language', locale);

  }

  if (! translationOverride || typeof translationOverride !== 'object' ) return;
  console.log('i18n: applying live override to language', locale, translationOverride);
  i18n.global.mergeLocaleMessage(locale, translationOverride);

}

const longDatetimeFormat = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

const shortDate = {
  month: 'short',
  year: '2-digit',
};

const mediumDate = {
  month: 'short',
  day: '2-digit',
  year: '2-digit',
};

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  allowComposition: true,
  silentFallbackWarn: true,
  globalInjection: true,
  fallbackWarn: false,
  missingWarn: false,
  messages: {
    en: { $vuetify: enVuetify, ... enCore },
  },
  datetimeFormats: {
    'en': { long: longDatetimeFormat, short: shortDate, medium: mediumDate },
  },
});

initLanguage('ru');

export default i18n;
