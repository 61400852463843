/* eslint-disable import/no-unresolved */
import { createVuetify } from 'vuetify';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import { useI18n } from 'vue-i18n';

import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader';
import { VDataTable, VDataTableServer } from 'vuetify/labs/VDataTable';
import { VDatePicker } from 'vuetify/labs/VDatePicker';
import { VStepper, VStepperItem, VStepperHeader, VStepperWindow, VStepperWindowItem, VStepperActions } from 'vuetify/labs/VStepper';
import { VAlert } from 'vuetify/components/VAlert';
import { VBtn } from 'vuetify/components/VBtn';

import 'vuetify/styles';
import '@/scss/overrides.scss';

import i18n from '@/i18n';
import { VToolbar, VContainer, VDivider, VSpacer, VMenu, VIcon, VList, VListItem, VListItemTitle } from 'vuetify/lib/components/index.mjs';

const colors = {
  primary: '#1976D2',
  secondary: '#424242',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
};

const dark = {
  dark: true,
  colors: { ...colors },
};

const light = {
  dark: false,
  colors: { ...colors,
    ['on-surface']: '#454545',
    ['on-background']: '#454545'},
};

export default createVuetify({
  components: {
    VSkeletonLoader,
    VDataTable,
    VDataTableServer,
    VDatePicker,
    VStepper,
    VStepperItem,
    VStepperHeader,
    VStepperWindow,
    VStepperWindowItem,
    VStepperActions,
    VAlert,
    VBtn,
    VToolbar,
    VContainer,
    VDivider,
    VSpacer,
    VMenu,
    VIcon,
    VList,
    VListItem,
    VListItemTitle,
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: { dark, light },
    variations: {
      colors: ['primary', 'secondary'],
      lighten: 4,
      darken: 4,
    },
  },
});
